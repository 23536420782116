<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content email-app-sidebar">
        <div
          :class="{'email-app-menu': true}"
        >
          <vue-perfect-scrollbar
            ref="filterScroll"
            class="sidebar-menu-list scroll-area"
            :settings="configSettingsScroll"
          >

            <div class="mt-3 px-2 d-flex justify-content-between">
              <h6 class="section-label mb-1">
                {{ $t('Separador') }}
              </h6>
            </div>

            <b-list-group
              v-if="filterData.listviewFilterSeparadores.length > 0"
              class="list-group-messages"
            >
              <b-list-group-item
                v-for="oTab in filterData.listviewFilterSeparadores.filter((oTab) => oTab.show === true)"
                :key="oTab.key"
                :style="{cursor: 'pointer' }"
                :active="((filterSelected.separadorSelecionado === oTab.key) ? true : false)"
                @click.stop.prevent="listviewFilterTab(oTab.key);$emit('close-left-sidebar')"
              >
                <span class="align-text-bottom line-height-1">{{ oTab.text }}</span>
              </b-list-group-item>
            </b-list-group>

            <!-- Extra space -->
            <h6
              class="section-label mt-1 mb-1 px-2"
            >
              {{ '\xa0' }}
            </h6>

          </vue-perfect-scrollbar>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import {
  BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import { mapGetters } from 'vuex'
import { configSettingsScroll } from '@core-custom/utils/ui'
import { mixFormGeral } from './mixins'

export default {
  components: {
    VuePerfectScrollbar,
    BListGroup,
    BListGroupItem,
  },
  mixins: [sizeIconsAction, mixFormGeral],
  props: {
    showMsgErrorRequest: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      maskDate: {
        date: true,
        delimiter: '-',
        datePattern: ['Y', 'm', 'd'],
      },
      search: [],
    }
  },
  computed: {
    ...mapGetters('financial_contacorrente', ['filterData', 'filterSelected', 'filterSelectedTotalFields', 'filterControl']),
  },
  methods: {
    encodeID(id) {
      return btoa(id)
    },
    listviewFilterTab(tab) {
      this.$store.dispatch('financial_contacorrente/listviewFilterTab', tab).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    resetListviewFilter() {
      this.$store.dispatch('financial_contacorrente/resetListviewFilterGeral').catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
  },
  setup() {
    return {
      configSettingsScroll,
    }
  },
}
</script>

<style lang="scss" scoped>
.email-application .content-area-wrapper .sidebar .email-app-sidebar .email-app-menu .sidebar-menu-list{
  position: relative;
  height: calc(100%);
}
</style>
