import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'

const defaultState = () => ({

  // variaveis dos selecionados
  listviewSelectedProcesso: ref(0),
  listviewSelectedSeparador: ref('contacorrente'),

  // variaveis dos filtros
  listviewFilterSeparadores: ([
    {
      key: 'contacorrente',
      text: i18n.t('Conta corrente'),
      show: true,
    },
    {
      key: 'notasabertas',
      text: i18n.t('Notas de recebimento/liquidação em aberto'),
      show: true,
    },
    {
      key: 'notasliquidadas',
      text: i18n.t('Notas de recebimento/liquidação liquidadas'),
      show: true,
    },
    {
      key: 'historico',
      text: i18n.t('Histórico'),
      show: true,
    },
  ]),

  // Variaveis gerais da listagem
  listviewInit: ref(1),
  listviewData: ref([]),
  viewInfoDataSelected: ref({}),
  tabSelectFilter: ref(''),
  loadingListview: ref(true),
  perPage: ref(-1),
  totalRows: ref(0),
  currentPage: ref(1),
  perPageOptions: ref([10, 25, 50, 100]),
  searchTxt: ref(''),
  listviewOrderBy: ref({
    column: '0',
    sort: 'desc',
  }),
  listviewNotasOrderBy: ref({
    column: '0',
    sort: 'desc',
  }),
  listview_txt_default: i18n.t('Nenhum resultado disponível'),
  listiview_txt_not_find: i18n.t('Não encontrou nenhum resultado'),
  listiview_txt_search: i18n.t('Descrição, Origem e ID Angariação'),
  listiview_txt_search_notas: i18n.t('ID Nota e IBAN'),
  listview_txt_loading: i18n.t('A carregar registos ...'),
  aFieldsOrder: [
    {
      name: '0',
      txt: i18n.t('Ordenar pela data de registo'),
    },
    {
      name: '2',
      txt: i18n.t('Ordenar pela origem do movimento'),
    },
    {
      name: '3',
      txt: i18n.t('Ordenar pelo descritivo do movimento'),
    },
    {
      name: '4',
      txt: i18n.t('Ordenar pelo ID da Angariação'),
    },
  ],
  aFieldsOrderNotas: [
    {
      name: '0',
      txt: i18n.t('Ordenar pela data de registo'),
    },
    {
      name: '2',
      txt: i18n.t('Ordenar pela nota de recebimento'),
    },
    {
      name: '3',
      txt: i18n.t('Ordenar pelo valor da nota de recebimento'),
    },
  ],
  filterSelectedTotalFields: ref(0),
  listviewSelectedFields: ref(0),
})

export default defaultState
