<template>
  <ul
    class="email-media-list"
  >
    <b-media
      v-for="(row) in listviewData"
      :key="row.id"
      tag="li"
      no-body
    >
      <b-media-body>
        <div class="mail-details">
          <b-container
            fluid
          >
            <b-row>
              <b-col md="12">
                <!-- eslint-disable vue/no-v-html -->
                <h5
                  v-if="row[1]"
                  class=""
                >
                  <span v-html="row[1]" />

                  <b-badge
                    v-if="row[7] === 1"
                    variant="light-danger"
                  >
                    {{ $t('Anulação') }}
                  </b-badge>

                </h5>
                <!--eslint-enable-->

              </b-col>
            </b-row>
            <b-row>
              <b-col md="3 mt-1">
                <span
                  v-if="row[2]"
                >
                  {{ row[2] }}
                </span>
                <div
                  v-if="row[2]"
                  class="mail-message"
                >
                  <p class="mb-0">
                    {{ $t('Data de registo') }}
                  </p>
                </div>
              </b-col>
              <b-col
                md="3 mt-1"
              >
                <span
                  v-if="row[3]"
                >
                  {{ row[3] }}
                </span>
                <div
                  v-if="row[3]"
                  class="mail-message"
                >
                  <p class="mb-0">
                    {{ $t('Origem do movimento') }}
                  </p>
                </div>
              </b-col>
              <b-col md="2 mt-1">
                <span
                  v-if="row[4]"
                >
                  {{ row[4] }}
                </span>
                <div
                  v-if="row[4]"
                  class="mail-message"
                >
                  <p class="mb-0">
                    {{ $t('ID Angariação') }}
                  </p>
                </div>
              </b-col>
              <b-col md="2 mt-1">
                <span
                  v-if="row[5] && !row[10]"
                  :class="row[7] === 1 ? 'text-danger' : 'text-success'"
                >
                  {{ formatPrice(row[5]) }}
                </span>
                <span
                  v-if="row[5] && row[10]"
                  :class="row[7] === 1 ? 'text-danger' : 'text-success'"
                >
                  {{ formatPrice(row[10]) }} <small>({{ $t('de') }} {{ formatPrice(row[5]) }})</small>
                </span>
                <span
                  v-if="row[6]"
                  class="text-danger"
                >
                  {{ formatPrice(row[6]) }}
                </span>
                <div
                  v-if="row[5] || row[6]"
                  class="mail-message"
                >
                  <p class="mb-0">
                    {{ $t('Valor') }}
                  </p>
                </div>
              </b-col>
              <b-col md="2 mt-1">
                <span
                  v-if="row[8]"
                >
                  {{ formatPrice(row[8]) }}
                </span>
                <div
                  v-if="row[8]"
                  class="mail-message"
                >
                  <p class="mb-0">
                    {{ $t('Saldo') }}
                  </p>
                </div>
                <span
                  v-if="row[9]"
                >
                  {{ row[9] }}
                </span>
                <div
                  v-if="row[9]"
                  class="mail-message"
                >
                  <p class="mb-0">
                    {{ $t('Nota de recebimento') }}
                  </p>
                </div>
              </b-col>
            </b-row>
          </b-container>
          <div class="mail-items" />
        </div>

        <!-- Tipo de cliente -->

        <!-- Itens de alerta -->
      </b-media-body>
    </b-media>
  </ul>
</template>

<script>
import {
  BMedia, BMediaBody, VBTooltip, BRow, BCol, BContainer, BBadge,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { formatPrice } from '@core-custom/utils/ui'

export default {
  components: {
    BMedia,
    BMediaBody,
    BRow,
    BCol,
    BContainer,
    BBadge,

  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    openModalDetailByRow: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      formatPrice,
    }
  },
  computed: {
    ...mapGetters('financial_contacorrente', ['listviewData', 'filterSelected', 'labelData']),
  },
  methods: {},
}
</script>
