<template>
  <ul
    class="email-media-list"
  >
    <b-media
      v-for="(row) in listviewData"
      :key="row.id"
      tag="li"
      no-body
    >
      <b-media-body>
        <div class="mail-details">
          <b-container
            fluid
          >
            <b-row>
              <b-col md="12">
                <h5
                  v-if="row[1]"
                  class=""
                >
                  {{ row[1] }}
                </h5>

              </b-col>
            </b-row>
            <b-row>
              <b-col md="3 mt-1">
                <span
                  v-if="row[0]"
                >
                  {{ row[0] }}
                </span>
                <div
                  v-if="row[0]"
                  class="mail-message"
                >
                  <p class="mb-0">
                    {{ $t('Data de registo') }}
                  </p>
                </div>
              </b-col>
              <b-col
                md="3 mt-1"
              >
                <span
                  v-if="row[2]"
                >
                  {{ row[2] }}
                </span>
                <div
                  v-if="row[2]"
                  class="mail-message"
                >
                  <p class="mb-0">
                    {{ $t('IBAN') }}
                  </p>
                </div>
              </b-col>
              <b-col md="2 mt-1">
                <span
                  v-if="row[3]"
                >
                  {{ row[3] }}
                </span>
                <div
                  v-if="row[3]"
                  class="mail-message"
                >
                  <p class="mb-0">
                    {{ $t('Retenção') }}
                  </p>
                </div>
              </b-col>
              <b-col md="2 mt-1">
                <span
                  v-if="row[4]"
                >
                  {{ row[4] }}
                </span>
                <div
                  v-if="row[4]"
                  class="mail-message"
                >
                  <p class="mb-0">
                    {{ $t('IVA') }}
                  </p>
                </div>
              </b-col>
              <b-col md="2 mt-1">
                <span
                  v-if="row[5]"
                  :class="row[7] === 1 ? 'text-success' : 'text-danger'"
                >
                  {{ formatPrice(row[5]) }}
                </span>
                <div
                  v-if="row[5]"
                  class="mail-message"
                >
                  <p class="mb-0">
                    {{ $t('A receber/A pagar') }}
                  </p>
                </div>
              </b-col>
            </b-row>
          </b-container>
          <div class="mail-items" />
        </div>

        <!-- Tipo de cliente -->

        <!-- Itens de alerta -->
      </b-media-body>
    </b-media>
  </ul>
</template>

<script>
import {
  BMedia, BMediaBody, VBTooltip, BRow, BCol, BContainer,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { formatPrice } from '@core-custom/utils/ui'

export default {
  components: {
    BMedia,
    BMediaBody,
    BRow,
    BCol,
    BContainer,

  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    openModalDetailByRow: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      formatPrice,
    }
  },
  computed: {
    ...mapGetters('financial_contacorrente', ['listviewData', 'filterSelected', 'labelData']),
  },
  methods: {},
}
</script>
