import api from '@core-custom/api/api'
import apiConfig from '@core-custom/api/apiConfig'
import i18n from '@/libs/i18n'
import defaultState from './defaultState'

export default {
  namespaced: true,
  state: defaultState,
  getters: {
    listviewInit: state => state.listviewInit.value,
    listviewData: state => state.listviewData.value,
    viewInfoDataSelected: state => state.viewInfoDataSelected.value,
    listviewOrderBy: state => state.listviewOrderBy.value,
    listviewNotasOrderBy: state => state.listviewNotasOrderBy.value,
    totalRows: state => state.totalRows.value,
    perPage: state => state.perPage.value,
    tabSelectFilter: state => state.tabSelectFilter.value,
    loadingListview: state => state.loadingListview.value,
    currentPage: state => state.currentPage.value,
    searchTxt: state => state.searchTxt.value,
    fieldsOrder: state => state.aFieldsOrder,
    fieldsOrderNotas: state => state.aFieldsOrderNotas,
    listview_txt_default: state => ({
      txt: state.listview_txt_default,
      txt_not_find: state.listiview_txt_not_find,
      txt_search: state.listiview_txt_search,
      txt_search_notas: state.listiview_txt_search_notas,
      txt_loading: state.listview_txt_loading,
    }),
    filterSelected: state => ({
      processoSelecionado: state.listviewSelectedProcesso.value,
      separadorSelecionado: state.listviewSelectedSeparador.value,
      totalFields: state.listviewSelectedFields.value,
    }),
    filterData: state => ({
      listviewFilterSeparadores: state.listviewFilterSeparadores,
    }),
    filterControl: state => ({
      disableHubsExternos: state.disableHubsExternos.value,
    }),
    filterSelectedTotalFields: state => state.filterSelectedTotalFields.value,
  },
  mutations: {
    resetState(state) {
      Object.assign(state, defaultState)
    },
    setLoadingListview(state, payload) {
      state.loadingListview.value = payload
    },
    setListviewData(state, payload) {
      state.listviewData.value = payload
    },
    setTotalRows(state, payload) {
      state.totalRows.value = payload
    },
    setFilterData(state, payload) {
      const index = Object.keys(payload)[0]
      state[index].value = payload[index]
    },
    setFilterSelected(state, payload) {
      const index = Object.keys(payload)[0]
      state[index].value = payload[index]
    },
    setSearchTxt(state, payload) {
      state.searchTxt.value = payload
    },
    setCurrentPage(state, payload) {
      state.currentPage.value = payload
    },
    setTabSelectFilter(state, payload) {
      state.listviewSelectedSeparador.value = payload
    },
    setListviewOrderBy(state, payload) {
      state.listviewOrderBy.value = {
        sort: payload.sort,
        column: payload.column,
      }
    },
    setListviewNotasOrderBy(state, payload) {
      state.listviewNotasOrderBy.value = {
        sort: payload.sort,
        column: payload.column,
      }
    },
    setListviewInit(state, payload) {
      state.listviewInit.value = payload
    },
    addFilterSelectedTotalFields(state) {
      state.filterSelectedTotalFields.value += 1
    },
    resetFilterSelectedTotalFields(state) {
      state.filterSelectedTotalFields.value = 0
    },
    resetAllFilterSelected(state) {
      state.filterSelectedTotalFields.value = 0
      state.listviewSelectedProcesso.value = ''
      state.searchTxt.value = ''
    },
    setPerPage(state, payload) {
      state.perPage.value = payload
    },
  },
  actions: {
    // Carregar listagem
    fetchListView({
      state, commit, dispatch,
    }, payload) {
      const listviewFilterParams = new FormData()

      let urlCustomLink = 'loadList'
      if (state.listviewSelectedSeparador.value === 'notasabertas' || state.listviewSelectedSeparador.value === 'notasliquidadas') {
        urlCustomLink = 'loadListNotasUser'

        if (state.listviewSelectedSeparador.value === 'notasabertas') {
          listviewFilterParams.append('estado', 0)
        }

        if (state.listviewSelectedSeparador.value === 'notasliquidadas') {
          listviewFilterParams.append('estado', 1)
        }
        commit('setPerPage', 25)
      } else {
        commit('setPerPage', -1)
      }

      if (state.listviewSelectedSeparador.value === 'historico') {
        urlCustomLink = 'loadListHistory'
        commit('setPerPage', 25)
      }

      if (state.searchTxt.value !== '' && state.searchTxt.value !== null) {
        listviewFilterParams.append('fSearch', state.searchTxt.value)
      }

      listviewFilterParams.append('fstartLetter', 'all')
      listviewFilterParams.append('loadContentRecords', 1)
      listviewFilterParams.append('start', ((state.currentPage.value - 1) * state.perPage.value))
      listviewFilterParams.append('length', state.perPage.value)

      if (state.listviewSelectedSeparador.value === 'notasabertas' || state.listviewSelectedSeparador.value === 'notasliquidadas') {
        if (state.listviewNotasOrderBy.value.column !== undefined) {
          listviewFilterParams.append('order[0][column]', state.listviewNotasOrderBy.value.column)
        }

        if (state.listviewNotasOrderBy.value.sort !== undefined) {
          listviewFilterParams.append('order[0][dir]', state.listviewNotasOrderBy.value.sort)
        }
      } else {
        if (state.listviewOrderBy.value.column !== undefined) {
          listviewFilterParams.append('order[0][column]', state.listviewOrderBy.value.column)
        }

        if (state.listviewOrderBy.value.sort !== undefined) {
          listviewFilterParams.append('order[0][dir]', state.listviewOrderBy.value.sort)
        }
      }

      if (!!payload && (payload.loadingDisable !== undefined) && (payload.loadingDisable === true)) {
        commit('setListviewInit', 1)
      } else {
        commit('setListviewData', [])
        commit('setTotalRows', 0)
        commit('setLoadingListview', true)
        commit('setListviewInit', 1)
      }

      dispatch('request/cancelTokensPending', null, { root: true })

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}contacorrente/${urlCustomLink}`, listviewFilterParams)
          .then(response => {
            if (response.data.data !== undefined) {
              commit('setListviewData', response.data.data)
              commit('setTotalRows', response.data.recordsTotal)
              commit('setLoadingListview', false)
              commit('setListviewInit', 0)
              resolve(true)
            } else {
              commit('setListviewData', [])
              commit('setTotalRows', 0)
              commit('setLoadingListview', false)
              commit('setListviewInit', 0)
              reject(new Error('Problema ao carregar a listagem'))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              commit('setListviewData', [])
              commit('setTotalRows', 0)
              commit('setLoadingListview', false)
              commit('setListviewInit', 0)
              reject(new Error(i18n.t('Problema ao carregar a listagem')))
            }
          })
      })
    },

    // Carrega os filtros conforme o separador ativo
    async showFiltersListview({ state, commit, dispatch }) {
      if (state.filterDataTabsListview.value.length > 0) {
        const oTabsListviewActive = state.filterDataTabsListview.value.filter(oTab => oTab.show === true)

        try {
          if (oTabsListviewActive[0].key) {
            commit('setTabSelectFilter', oTabsListviewActive[0].key)
            dispatch('showFiltersListviewByTabSelected')
          }
        } catch {
        //
        }
      }
    },

    showFiltersListviewByTabSelected({ commit }) {
      commit('setShowFiltersListviewByTabSelected')
      commit('setFilterData', { filterDataLoad: true })
    },

    // Ordenação da listagem
    listviewFilterOrderBy({ state, commit, dispatch }, field) {
      if ((!!state.listviewOrderBy.value.column) && (state.listviewOrderBy.value.column === field)) {
        if (state.listviewOrderBy.value.sort === 'asc') {
          commit('setListviewOrderBy', {
            sort: 'desc',
            column: field,
          })
        } else {
          commit('setListviewOrderBy', {
            sort: 'asc',
            column: field,
          })
        }
      } else {
        commit('setListviewOrderBy', {
          sort: 'asc',
          column: field,
        })
      }

      return dispatch('fetchListView')
    },

    // Ordenação da listagem
    listviewNotasFilterOrderBy({ state, commit, dispatch }, field) {
      if ((!!state.listviewNotasOrderBy.value.column) && (state.listviewNotasOrderBy.value.column === field)) {
        if (state.listviewNotasOrderBy.value.sort === 'asc') {
          commit('setListviewNotasOrderBy', {
            sort: 'desc',
            column: field,
          })
        } else {
          commit('setListviewNotasOrderBy', {
            sort: 'asc',
            column: field,
          })
        }
      } else {
        commit('setListviewNotasOrderBy', {
          sort: 'asc',
          column: field,
        })
      }

      return dispatch('fetchListView')
    },

    setLeadIDSelected({ commit }, idLead) {
      commit('setSelectedLeadsID', idLead)
    },

    setSearchTxt({ commit, dispatch }, value) {
      commit('setSearchTxt', value)
      commit('setCurrentPage', 1)
      return dispatch('fetchListView', { loadingDisable: true })
    },

    setCurrentPage({ commit, dispatch }, value) {
      commit('setCurrentPage', value)
      return dispatch('fetchListView')
    },

    pullToRefreshListview({ commit, dispatch }) {
      commit('setCurrentPage', 1)
      return dispatch('fetchListView', { loadingDisable: true })
    },

    // Metodos do filtro lateral - Alterar separador
    listviewFilterTab({ commit, dispatch }, tab) {
      commit('setTabSelectFilter', tab)
      commit('resetAllFilterSelected')
      // commit('setShowFiltersListviewByTabSelected')
      return dispatch('fetchListView')
    },

    // Limpar todos os filtros selecionados
    resetListviewFilterGeral({ commit, dispatch }) {
      commit('resetAllFilterSelected')
      return dispatch('fetchListView')
    },

    selectFilterIntervaloDataInicio({ commit, dispatch }, value) {
      commit('setIntervaloDataInicio', value)
      dispatch('fetchListView')
    },

    selectFilterIntervaloDataFim({ commit, dispatch }, value) {
      commit('setIntervaloDataFim', value)
      dispatch('fetchListView')
    },

  },
}
